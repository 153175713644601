import { Grid } from "@mui/material";
import PageHeader from "components/@extended/PageHeader";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import SequenceForm from "sections/product-sequence/SequenceForm";
import {
  useUpdateProductSequenceMutation,
  useGetEngineProductByIdQuery,
} from "store/reducers/smartEngineProduct";
import { UpdateSmartProductSequencePayLoad } from "types/smart-engine-product";
import Loader from "components/Loader";
import isEqual from "lodash/isEqual";
import { isApiResponse } from "helper/isApiResponse";
import allowNullValue from "helper/allowNullValue";

const navigation = [
  { name: "HCT Config", href: "/hct-config/smart-engine-products/list" },
  { name: "Smart Engine: Products" },
];

const UpdateSequence = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [updateSequence, { isSuccess, isError, error, isLoading }] =
    useUpdateProductSequenceMutation();

  const { data, isFetching: isFetchingProduct } = useGetEngineProductByIdQuery(
    id ?? "",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const onSubmit = async (payload: UpdateSmartProductSequencePayLoad) => {
    if (
      isEqual(payload.productSequence, data?.data.ProductSequence) &&
      isEqual(payload.ruleID, data?.data.RuleID)
    ) {
      enqueueSnackbar("No change made", {
        variant: "error",
      });
      return;
    }
    const info = allowNullValue(payload)
    await updateSequence(info);
  };

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Sequence Updated Successfully", {
        variant: "success",
      });
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (isError) {
      if (isApiResponse(error)) {
        if (error.data.error) {
          enqueueSnackbar(error.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong. Please try again later", {
            variant: "error",
          });
        }
      }
    }
  }, [error, isError]);

  if (isFetchingProduct) {
    return <Loader />;
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader list={navigation} headerText={"Add New Sequence"} />
        </Grid>
        <Grid item xs={12}>
          <SequenceForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            isSubmitting={isLoading}
            info={data?.data}
          />
        </Grid>
      </Grid>
    );
  }
};

export default UpdateSequence;
