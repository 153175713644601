import { isEmpty, isNull } from "lodash";

function allowNullValue(obj: any) {
  for (var propName in obj) {
    if (typeof obj[propName] === "string" && isEmpty(obj[propName])) {obj[propName] = null}
    if (((typeof obj[propName] === "object" &&
        obj[propName] instanceof Date === false &&
        isNaN(obj[propName]?.valueOf()) && !isNull(obj[propName])) ||
        Array.isArray(typeof obj[propName])) &&
      isEmpty(obj[propName])
    ) {
      delete obj[propName];
    }
  }

  return obj;
}

export default allowNullValue;
