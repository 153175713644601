import { useContext } from "react";
import { ConfigContext } from "contexts/ConfigContext";

// ==============================|| CONFIG - HOOKS  ||============================== //

const useConfig = () => {
  const context = useContext(ConfigContext);

  if (!context)
    throw new Error("ConfigContext must be used inside its provider");

  return context;
};

export default useConfig;
