import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import { CreateRuleResponse, CreateRule, UpdateRuleResponse, UpdateRule, DeleteRuleResponse, Rule, RuleByIdResponse, RuleListResponse, SearchParam, DeleteRule, ActiveRuleListResponse, ActiveRuleRequestBody } from "types/ruleMaster";
import { current } from "@reduxjs/toolkit";

export const RULE_MASTER_API_REDUCER_KEY = "ruleMasterApi";

export const ruleMasterApi = createApi({
    reducerPath: RULE_MASTER_API_REDUCER_KEY,
    baseQuery: baseQuery,
    tagTypes: ["RuleMaster"],
    endpoints: (builder) => ({
        getRuleList: builder.query<RuleListResponse, SearchParam>({
            query: (args: SearchParam) => {
                return {
                    url: ApiConstant.RULE_LIST,
                    method: "POST",
                    body: args
                }
            },
            providesTags: ["RuleMaster"],
        }),
        getActiveRuleList: builder.query<ActiveRuleListResponse, ActiveRuleRequestBody>({
            query: (args: ActiveRuleRequestBody) => {
                return {
                    url: ApiConstant.GET_ACTIVE_RULES,
                    method: 'POST',
                    body: args
                }
            }
        }),
        createRule: builder.mutation<CreateRuleResponse, CreateRule>({
            query: (args: CreateRule) => {
                return {
                    url: ApiConstant.CREATE_RULE,
                    method: 'POST',
                    body: args
                }
            },
            transformErrorResponse: (response: {
                data: { error: string; message: string };
                status: number;
              }): string => response.data.error
        }),
        updateRule: builder.mutation<UpdateRuleResponse, UpdateRule>({
            query: (args: UpdateRule) => {
                return {
                    url: ApiConstant.UPDATE_RULE,
                    method: 'PATCH',
                    body: args
                }
            },
            transformErrorResponse: (response: {
                data: { error: string; message: string };
                status: number;
              }): string => response.data.error
        }),
        deleteRuleById: builder.mutation<DeleteRuleResponse, DeleteRule>({
            query: (args: DeleteRule) => {
                const { ID } = args
                return {
                    url: ApiConstant.DELETE_RULE,
                    method: 'PATCH',
                    body: { ID }
                }
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                  const { ID, ...rest } = args;
                  const { data: updatedInfo } = await queryFulfilled;
                  console.log("Data", updatedInfo);
                  const patchedResult = dispatch(
                    ruleMasterApi.util.updateQueryData(
                      "getRuleList",
                      rest,
                      (draft) => {
                        console.log("UUData", draft.data);
                        const objIndex = draft.data.findIndex(
                          (item, index) => item.ID === ID
                        );
                        if (objIndex !== -1) {
                          draft.data.splice(objIndex, 1);
                        }
                        console.log("Draft Info", current(draft.data));
                      }
                    )
                  );
                  console.log("Pathched", patchedResult);
                } catch (error) {
                  console.log("Error", error);
                }
              },
        }),
        getRuleById: builder.query<RuleByIdResponse, Rule>({
            query: (args: Rule) => {
                return {
                    url: ApiConstant.GET_RULE_BY_ID,
                    method: 'POST',
                    body: args
                }
            }
        })
    })
})

export const { useGetRuleListQuery, useCreateRuleMutation, useUpdateRuleMutation, useDeleteRuleByIdMutation, useGetRuleByIdQuery, useGetActiveRuleListQuery } = ruleMasterApi